import React from 'react';
import { ReactComponent as Location } from 'assets/location.svg';
import { ReactComponent as Phone } from 'assets/phone.svg';
import { ReactComponent as Telegram } from 'assets/telegram-icon.svg';
import { ReactComponent as Whatsapp } from 'assets/whatsapp-color-icon.svg';

const Footer = () => (
  <footer className="footer">
    <div className="footer-sections">
      <div className="contacts-info-wrapper footer-section">
        <div className="address info">
          <Location />
          г. Санкт-Петербург, Гражданский пр-т, д.26, оф.45
        </div>
        <a
          className="tel info"
          href="tel:+79811205052"
        >
          <Phone />
          +7 981 120-50-52
        </a>

        <a className="info" href="https://t.me/your_telegram" rel="noopener noreferrer" target="_blank">
          <Telegram />
          Telegram
        </a>
        <a className="info" href="https://wa.me/your_whatsapp_number" rel="noopener noreferrer" target="_blank">
          <Whatsapp />
          WhatsApp
        </a>
      </div>
      <div className="footer-section">
        <p>Монтаж и обслуживание лифтовых систем</p>
        <p>Механические и гидравлические подъемные механизмы</p>
        <p>Работа с госзаказом и Министерством обороны РФ.</p>
      </div>
      <div className="footer-section">
        <p>
          КОМПАНИЯ &quot;НЕВА-ЛИФТ-СЕРВИС&quot;
          - ОФИЦИАЛЬНЫЙ ПАРТНЁР ЕВРОПЕЙСКОГО ПРОИЗВОДИТЕЛЯ ЛИФТОВОГО ОБОРУДОВАНИЯ - &quot;KLEEMANN&quot; В РОССИИ
        </p>
      </div>
    </div>

    <div>
      Нева Лифт &copy;
      {' '}
      {new Date().getFullYear()}
    </div>
  </footer>
);

export default Footer;
