import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from 'react-scroll';
import About from 'components/Main/About';
import Contacts from 'components/Main/Contacts';
import ImageGallery from 'components/Main/Gallery';
import Intro from 'components/Main/Intro';
import OurServices from 'components/Main/OurServices';
import Partners from 'components/Main/Partners';
import WhyWe from 'components/Main/WhyWe';

const Main = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      scroller.scrollTo(location.hash.substring(1), {
        smooth: true,
        duration: 500,
      });
    } else {
      scroll.scrollToTop();
    }
  }, [location]);

  return (
    <>
      <Intro />
      <OurServices />
      <ImageGallery />
      <WhyWe />
      <About />
      <Partners />
      <Contacts />
    </>
  );
};

export default Main;
