import React from 'react';
// import boss from 'assets/boss.jpg';

const About = () => (
  <div className="about-wrapper" id="about">
    <h2 className="content-title">О компании</h2>
    <div className="content">
      <p>
        Компания &quot;Нева-Лифт-Сервис&quot; специализируется на профессиональном монтаже и ремонте лифтовых систем.
        Мы гарантируем высокое качество услуг, благодаря опыту наших сертифицированных специалистов и применению передовых технологий.
      </p>
      <p>
        Наша цель - обеспечить безопасность и комфорт вашего передвижения, оперативно реагируя на любые запросы
        и потребности клиентов. Доверьте нам свои лифты, и вы получите надежного партнера для долгосрочного сотрудничества.
      </p>

      {/* <div className="employment"> */}
      {/*  <div className="employee-card"> */}
      {/*    <div className="photo-container"> */}
      {/*      <img alt="Employee" className="photo" src={boss} /> */}
      {/*    </div> */}
      {/*    <div className="details"> */}
      {/*      <div className="position">Директор</div> */}
      {/*      <div className="name">Иванов Иван Иванович</div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/*  <div className="employee-card"> */}
      {/*    <div className="photo-container"> */}
      {/*      <img alt="Employee" className="photo" src={boss} /> */}
      {/*    </div> */}
      {/*    <div className="details"> */}
      {/*      <div className="position">Заместитель директора</div> */}
      {/*      <div className="name">Петров Пётр Петрович</div> */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </div> */}
    </div>
  </div>
);

export default About;
