import React from 'react';
import { Link } from 'react-router-dom';
import HamburgerMenu from 'components/layout/Header/HamburgerMenu';
import { ReactComponent as Location } from 'assets/location.svg';
import logo3 from 'assets/logo-3.jpg';
import { ReactComponent as Phone } from 'assets/phone.svg';

const Header = () => (
  <header className="header">
    <HamburgerMenu />
    <div className="header-content content">
      <Link to="/">
        <img alt="Нева Лифт" className="logo" src={logo3} />
      </Link>

      <div className="contacts-info">
        <div className="header-contacts">
          <div className="address info">
            <Location />
            г. Санкт-Петербург, Гражданский пр-т, д. 26, оф. 45
          </div>
          <a className="tel info" href="tel:+79811205052">
            <Phone />
            +7 981 120-50-52
          </a>
        </div>
      </div>
    </div>
    <div className="navigation">
      <ul className="content">
        <li>
          <Link to="/">Главная</Link>
        </li>
        <li>
          <Link to="/#services">Услуги</Link>
        </li>
        <li>
          <Link to="/#contacts">Контакты</Link>
        </li>
        <li>
          <Link to="/#about">О компании</Link>
        </li>
        <li>
          <Link to="/details">Реквизиты</Link>
        </li>
      </ul>
    </div>
  </header>
);

export default Header;
