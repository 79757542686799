const Privacy = () => (
  <div className="content">
    <h2 className="content-title">Согласие на обработку персональных данных</h2>

    <p>
      Настоящим в соответствии с Федеральным законом № 152-ФЗ «О персональных данных» от 27.07.2006 года свободно,
      своей волей и в своем интересе выражаю свое безусловное согласие на обработку моих персональных данных ООО
      &quot;НЕВА-ЛИФТ-СЕРВИС&quot;, зарегистрированным в соответствии с законодательством РФ по адресу:
      https://neva-lift.ru (далее по тексту - Оператор).
      Персональные данные - любая информация, относящаяся к определенному или определяемому на основании такой
      информации физическому лицу.
      Настоящее Согласие выдано мною на обработку следующих персональных данных:
      Список полей формы
    </p>
    <p>
      Согласие дано Оператору для совершения следующих действий с моими персональными данными с использованием
      средств автоматизации и/или без использования таких средств: сбор, систематизация, накопление, хранение,
      уточнение (обновление, изменение), использование, обезличивание, передача третьим лицам для указанных ниже
      целей, а также осуществление любых иных действий, предусмотренных действующим законодательством РФ как
      неавтоматизированными, так и автоматизированными способами.
      Данное согласие дается Оператору и третьему лицу(-ам) для обработки моих персональных данных в следующих
      целях:
    </p>
    <p>
      - предоставление мне услуг/работ;
      - направление в мой адрес уведомлений, касающихся предоставляемых услуг/работ;
      - подготовка и направление ответов на мои запросы;
      - направление в мой адрес информации, в том числе рекламной, о мероприятиях/товарах/услугах/работах
      Оператора.
    </p>
    <p>
      Настоящее согласие действует до момента его отзыва путем направления соответствующего уведомления на
      электронный адрес neva-lift-s@yandex.ru. В случае отзыва мною согласия на обработку персональных данных
      Оператор вправе продолжить обработку персональных данных без моего согласия при наличии оснований,
      указанных
      в пунктах 2 – 11 части 1 статьи 6, части 2 статьи 10 и части 2 статьи 11 Федерального закона №152-ФЗ «О
      персональных данных» от 26.06.2006 г.
    </p>
  </div>
);

export default Privacy;
