import {
  BrowserRouter as Router, Navigate, Route, Routes,
} from 'react-router-dom';
import Details from 'components/Details';
import Footer from 'components/layout/Footer/Footer';
import Header from 'components/layout/Header/Header';
import Main from 'components/Main';
import Contacts from 'components/Main/Contacts';
import Privacy from 'components/Privacy';

const App = () => (
  <div className="page-wrapper">
    <Router>
      <Header />
      <main>
        <Routes>
          <Route element={<Main />} path="/" />
          <Route element={<Contacts />} path="/contacts" />
          <Route element={<Details />} path="/details" />
          <Route element={<Privacy />} path="/privacy-policy" />
          <Route element={<Navigate replace to="/" />} path="*" />
        </Routes>
      </main>
      <Footer />
    </Router>
  </div>
);

export default App;
