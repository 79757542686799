import { ReactComponent as PersonIcon } from 'assets/person.svg';
import { ReactComponent as QalityIcon } from 'assets/qality.svg';
import { ReactComponent as RepairIcon } from 'assets/repair.svg';
import { ReactComponent as WalletIcon } from 'assets/wallet.svg';

const advantages = [
  {
    icon: <QalityIcon />,
    title: 'Высокое качество',
    description: 'Весь наш ассортимент оборудования сертифицирован. Мы предоставляем гарантию на'
        + ' все виды монтажных, ремонтных и сервисных работ.',
  },
  {
    icon: <PersonIcon />,
    title: 'Опытные специалисты',
    description: 'Наши команды состоят из специалистов с опытом работы от 2 до 30 лет.',
  },
  {
    icon: <RepairIcon />,
    title: 'Полный спектр услуг',
    description: 'Мы предлагаем полный комплекс услуг: от подбора лифтового оборудования до замены деталей'
        + ' лифтов и эскалаторов в рамках технического обслуживания.',
  },
  {
    icon: <WalletIcon />,
    title: 'Ценообразование',
    description: 'Мы придерживаемся честного ценообразования и предлагаем услуги по средним рыночным расценкам.',
  },
];

const WhyWe = () => (
  <div className="content">
    <h2 className="content-title">Почему мы</h2>

    <div className="services-wrapper">
      {advantages.map((service) => (
        <div key={service.title} className="service-item why-we-item">
          {service.icon}
          <h3>{service.title}</h3>
          <p>{service.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default WhyWe;
