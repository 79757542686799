import React from 'react';
import ContactForm from 'components/Main/ContactForm';

const Contacts = () => (
  <div className="contacts-wrapper" id="contacts">
    <h2 className="content-title">Контакты</h2>

    <div className="contacts-container content">
      <ContactForm />
    </div>

    <iframe
      height="300"
      src="https://yandex.ru/map-widget/v1/?um=constructor%3A77bb2e64e836bd3d90fae1f7f1322ea3ab3dc44b3d739a66f988e428144e6f56&amp;source=constructor"
      style={{ border: 0 }}
      title="0"
      width="100%"
    />
  </div>
);

export default Contacts;
