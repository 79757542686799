import React, { useEffect, useRef, useState } from 'react';
import introBg from 'assets/intro-bg.webp';
import klemann from 'assets/partners/kleemann-logo.png';
import video from 'assets/videoplayback.mp4';

const Intro = () => {
  const videoRef = useRef(null);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  useEffect(() => {
    const videoEl = videoRef.current;

    if (videoEl) {
      const handleTimeUpdate = () => {
        // eslint-disable-next-line no-magic-numbers
        if (videoEl.currentTime >= videoEl.duration - 4) {
          videoEl.currentTime = 0;
          videoEl.play();
        }
      };

      videoEl.addEventListener('timeupdate', handleTimeUpdate);

      // Обработчик события загружено
      const handleLoadedData = () => {
        setIsVideoLoaded(true);
      };

      videoEl.addEventListener('loadeddata', handleLoadedData);

      return () => {
        videoEl.removeEventListener('timeupdate', handleTimeUpdate);
        videoEl.removeEventListener('loadeddata', handleLoadedData);
      };
    }

    return null;
  }, []);

  return (
    <div className="intro-wrapper">
      <div className={`background-section ${isVideoLoaded ? '' : 'loading'}`}>
        {!isVideoLoaded && <img alt="Loading" className="loading-image" src={introBg} />}
        <video ref={videoRef} autoPlay loop muted onLoadedData={() => setIsVideoLoaded(true)}>
          <source src={video} type="video/mp4" />
        </video>
        <div className="overlay">
          <div>
            <h1>Монтаж и обслуживание лифтовых систем</h1>
            <h3>
              Механические и
              {' '}
              <span>гидравлические</span>
              {' '}
              подъемные механизмы. Работа с
              {' '}
              <span>госзаказом и Министерством обороны РФ.</span>
            </h3>
            <a href="#contacts">
              <button className="slider-button">Подробнее</button>
            </a>
          </div>
        </div>
      </div>

      <div className="promo-wrapper content wrapper">
        <img alt="Klemann" src={klemann} />
        <p>
          КОМПАНИЯ &quot;НЕВА-ЛИФТ-СЕРВИС&quot;
          - ОФИЦИАЛЬНЫЙ ПАРТНЁР ЕВРОПЕЙСКОГО ПРОИЗВОДИТЕЛЯ ЛИФТОВОГО ОБОРУДОВАНИЯ - &quot;KLEEMANN&quot; В РОССИИ
        </p>
      </div>
    </div>
  );
};

export default Intro;
