/* eslint-disable import/no-unresolved */
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import img2 from 'assets/partners/eurostroy.svg';
import img5 from 'assets/partners/eurotechnik.svg';
import img1 from 'assets/partners/kleemann.svg';
import img4 from 'assets/partners/panda.svg';
import 'swiper/css';

const images = [
  {
    id: 1,
    img: img1,
    title: 'KLEEMANN',
  },
  {
    id: 2,
    img: img2,
    title: 'Еврострой',
  },
  {
    id: 4,
    img: img4,
    title: 'Автомобильные лифты Panda',
  },
  {
    id: 5,
    img: img5,
    title: 'Европейская Электротехника',
  },
];

const Partners = () => (
  <div className="wrapper content">
    <h2 className="content-title">Партнёры</h2>

    <div className="partner-wrapper">
      <Swiper
        autoplay={{ delay: 2000 }}
        breakpoints={{
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          // when window width is < 768px
          0: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
        grabCursor
        loop
        modules={[Autoplay, Pagination]}
        pagination
        slidesPerView="3"
        spaceBetween={0}
      >
        {images.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="partner-slide">
              <img alt={item.title} src={item.img} />
              <h3>{item.title}</h3>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  </div>
);

export default Partners;
