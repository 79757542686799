import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import * as Yup from 'yup';
import { sendMessage } from 'api/api';

import {
  ErrorMessage, Field, Form, Formik,
} from 'formik';

const phoneNumberMask = [
  '+',
  '7',
  ' ',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

const maxNameLength = 20;
const maxMessageLength = 500;

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(maxNameLength, 'Имя не должно превышать 20 символов')
    .required('Обязательное поле'),
  phone: Yup.string()
    .matches(/\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/, 'Неверный формат номера телефона')
    .required('Обязательное поле'),
  message: Yup.string()
    .max(maxMessageLength, 'Сообщение не должно превышать 500 символов')
    .matches(/[\s\S]*/g, 'Только печатаемые символы клавиатуры'),
});

const ContactForm = () => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const handleSubmit = async (values) => {
    try {
      await sendMessage(values);
      setSubmitMessage('Заявка отправлена, мы свяжемся с вами в ближайшее время!');
    } catch (e) {
      setSubmitMessage('Произошла ошибка, попробуйте позвонить нам.');
    } finally {
      setIsSubmitSuccess(true);
    }
  };

  return (
    <div className="form-content">
      <div className="form__heading center">
        <h3 className="form-title">Заказать консультацию</h3>
        <p className="form-sub-title">Заполните эту форму и мы перезвоним вам в течении 30 минут</p>
      </div>

      <div className="wpcf7 js" dir="ltr" id="wpcf7-f42-o1" lang="ru-RU">
        <div className="screen-reader-response">
          <p aria-atomic="true" aria-live="polite" role="status" />
          <ul />
        </div>
        <Formik
          initialValues={{ name: '', phone: '', message: '' }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {(props) => (
            <Form>
              <div className="form-control">
                <div className="label">Ваше имя</div>
                <span className="form-control-wrap">
                  <Field
                    className="form-input"
                    name="name"
                    size="40"
                    type="text"
                  />
                </span>
                <ErrorMessage
                  name="name"
                  render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)}
                />
              </div>
              <div className="form-control">
                <div className="label">Номер телефона</div>
                <span className="form-control-wrap">
                  <Field
                    name="phone"
                    render={({ field }) => (
                      <MaskedInput guide placeholderChar="_" {...field} className="form-input" mask={phoneNumberMask} />
                    )}
                  />
                </span>
                <ErrorMessage
                  name="phone"
                  render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)}
                />
              </div>
              <div className="form-control">
                <div className="label">Сообщение</div>
                <span className="form-control-wrap">
                  <Field
                    as="textarea"
                    className="form-input form-textarea"
                    name="message"
                    rows={5}
                  />
                </span>
                <ErrorMessage
                  name="message"
                  render={(errorMessage) => (<span className="form-error">{errorMessage}</span>)}
                />
              </div>
              <div className="form__policy">
                <p>
                  Нажимая кнопку вы соглашаетесь с согласием на
                  {' '}
                  <Link to="/privacy-policy/">
                    обработку персональных
                    данных
                  </Link>
                  {' '}
                  в форму обратной связи
                </p>
              </div>
              <div className="form__submit">

                <button
                  className="submit-button"
                  disabled={props.isSubmitting}
                  type="submit"
                >
                  Отправить
                </button>

                {isSubmitSuccess && (
                <div className="success-message-wrapper">
                  <span>{submitMessage}</span>
                </div>
                )}

              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ContactForm;
