import React from 'react';

const Details = () => (
  <div className="content">
    <h2 className="content-title">Реквизиты</h2>
    <p>
      <b>Генеральный директор</b>
      {' '}
      - Бальдин Валентин Алексеевич
    </p>
    <p>
      <b>Технический директор</b>
      {' '}
      - Стецко Владимир Юрьевич
    </p>
    <p className="requisites">
      <span>
        <b>Номер счёта:</b>
        {' '}
        40702810723040000059
      </span>
      <span>
        <b>Валюта:</b>
        {' '}
        RUR
      </span>
      <span>
        <b>Название:</b>
        {' '}
        ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ &quot;НЕВА-ЛИФТ-СЕРВИС&quot;
      </span>
      <span>
        <b>ИНН:</b>
        {' '}
        4212044462
      </span>
      <span>
        <b>КПП:</b>
        {' '}
        421201001
      </span>
      <span>
        <b>Банк:</b>
        {' '}
        ФИЛИАЛ &quot;НОВОСИБИРСКИЙ&quot; АО &quot;АЛЬФА-БАНК&quot;
      </span>
      <span>
        <b>БИК:</b>
        {' '}
        045004774
      </span>
      <span>
        <b>Кор. счёт:</b>
        {' '}
        30101810600000000774
      </span>
      <span>
        <b>Адрес получателя:</b>
        {' '}
        г. Санкт-Петербург, Гражданский проспект, д. 26, оф. 45
      </span>
    </p>
  </div>
);

export default Details;
