import { ReactComponent as DesignIcon } from 'assets/designing.svg';
import { ReactComponent as ImproveIcon } from 'assets/improvement.svg';
import { ReactComponent as InstallIcon } from 'assets/installation.svg';
import { ReactComponent as MaintenanceIcon } from 'assets/maintenance.svg';

const services = [
  {
    icon: <DesignIcon />,
    title: 'Проектирование',
    description: 'Мы предоставляем услуги по проектированию шахт под определенный вид лифтов на момент начала'
        + ' строительства здания, а также по подбору и проектированию лифтов для уже построенных объектов'
        + ' и старых зданий.',
  },
  {
    icon: <InstallIcon />,
    title: 'Монтаж',
    description: 'Осуществляем монтаж пассажирских, грузовых, коттеджных, гидравлических лифтов,'
        + ' а также автомобильных подъемников.',
  },
  {
    icon: <ImproveIcon />,
    title: 'Модернизация',
    description: 'Работы по модернизации лифтового оборудования позволяют улучшить дизайн кабины'
        + ' и повысить эффективность и безопасность лифта с минимальным затратами.',
  },
  {
    icon: <MaintenanceIcon />,
    title: 'Техническое обслуживание',
    description: 'Наши специалисты обеспечат своевременное техническое обслуживание лифта, выявление'
        + ' и устранение возникающих неполадок. Это позволит гарантировать постоянную и безопасную работу '
        + 'лифтового оборудования.',
  },
];

const OurServices = () => (
  <div className="wrapper content" id="services">
    <h2 className="content-title">Наши услуги</h2>

    <div className="services-wrapper">
      {services.map((service) => (
        <div key={service.title} className="service-item">
          {service.icon}
          <h3>{service.title}</h3>
          <p className="service-item-text">{service.description}</p>
        </div>
      ))}
    </div>
  </div>
);

export default OurServices;
