import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Location } from 'assets/location.svg';
import { ReactComponent as Phone } from 'assets/phone.svg';
import { ReactComponent as Telegram } from 'assets/telegram-icon.svg';
import { ReactComponent as Whatsapp } from 'assets/whatsapp-color-icon.svg';

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <div>
      <button className="hamburger-icon" onClick={toggleMenu}>
        ☰
      </button>
      <div className={`menu ${isOpen ? 'open' : ''}`}>
        <button className="close-icon" onClick={toggleMenu}>
          ✕
        </button>
        <ul>
          <li><Link to="/">Главная</Link></li>
          <li><Link to="/#services">Услуги</Link></li>
          <li><Link to="/#contacts">Контакты</Link></li>
          <li><Link to="/#about">О компании</Link></li>
          <li><Link to="/details">Реквизиты</Link></li>
        </ul>

        <div className="menu-contacts-info">
          <div className="address info">
            <Location />
            г. Санкт-Петербург, Гражданский пр-т, д. 26, оф. 45
          </div>
          <a
            className="tel info"
            href="tel:+79811205052"
          >
            <Phone />
            +7 981 120-50-52
          </a>
          <a className="info" href="https://t.me/your_telegram" rel="noopener noreferrer" target="_blank">
            <Telegram />
            Telegram
          </a>
          <a className="info" href="https://wa.me/your_whatsapp_number" rel="noopener noreferrer" target="_blank">
            <Whatsapp />
            WhatsApp
          </a>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
