/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import img1 from 'assets/works/1.jpg';
import img2 from 'assets/works/2.jpg';
import img3 from 'assets/works/3.jpg';
import img4 from 'assets/works/4.jpg';
import img5 from 'assets/works/5.jpg';
import img6 from 'assets/works/6.jpg';
import img7 from 'assets/works/7.jpg';
import 'swiper/css';

const images = [
  {
    src: img1, caption: 'Объект', width: 284, height: 396, id: 1,
  },
  {
    src: img2, caption: 'Объект', width: 284, height: 396, id: 2,
  },
  {
    src: img3, caption: 'Объект', width: 571, height: 396, id: 3,
  },
  {
    src: img4, caption: 'Объект', width: 284, height: 425, id: 4,
  },
  {
    src: img5, caption: 'Объект', width: 284, height: 425, id: 5,
  },
  {
    src: img6, caption: 'Объект', width: 284, height: 189, id: 6,
  },
  {
    src: img7, caption: 'Объект', width: 284, height: 189, id: 7,
  },
];

const ImageGallery = () => {
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsSliderOpen(true);
  };

  const handleCloseSlider = () => {
    setIsSliderOpen(false);
  };

  return (
    <div className="gallery-wrapper wrapper content">
      <h2 className="content-title">Наши работы</h2>

      <Gallery
        className="image-gallery"
        enableImageSelection={false}
        images={images}
        onClick={(index) => handleImageClick(index)}
        rowHeight={396}
      />

      {isSliderOpen && (
        <div className="slider-overlay" onClick={handleCloseSlider}>
          <Swiper
            className="image-slider"
            grabCursor
            initialSlide={currentImageIndex}
            loop
            modules={[Navigation, Pagination]}
            pagination={{ clickable: true }}
            slidesPerView={1}
            spaceBetween={30}
          >
            {images.map((image) => (
              <SwiperSlide key={image.id}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <img alt={image.caption} src={image.src} style={{ height: '80vh', width: 'auto' }} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
